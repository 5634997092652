import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide43/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide43/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide43/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide43/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide43/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Habilidades blandas que deben tener los agentes de atención al cliente
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      El 60% de los problemas empresariales se atribuyen a una comunicación
      deficiente. Esta destreza debe ser la primera que perfecciones, ya que,
      especialmente en atención al cliente, las llamadas telefónicas siguen
      siendo el método preferido por los consumidores.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    No obstante, la importancia de equilibrar habilidades duras y blandas no
    debe subestimarse al buscar profesionales de servicio al cliente integral.
    Para garantizar un equipo equilibrado, es esencial que los empleados posean
    tanto habilidades técnicas como habilidades blandas en servicio al cliente.
    <br /> <br />
    Si te preguntas por qué esto es crucial, en esta guía, se explicarán las
    habilidades blandas y su relevancia en el servicio al cliente.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Habilidad 1: La escucha activa.",
      },
      {
        key: 2,
        text: "Habilidad 2: Buena comunicación.",
      },
      {
        key: 3,
        text: " Habilidad 4: Empatía.",
      },
      {
        key: 4,
        text: "Habilidad 5: Adaptabilidad.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
